<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <tabel_data :rsData=rsData :balai=balai @DetailData=DetailData @LoadData=LoadData></tabel_data>
        </b-card>
      </b-col>
    </b-row>
    <!-- <edik-kondisi-rusa-jalan></edik-kondisi-rusa-jalan> -->
    <edit-jalan-aspal :rsDetail=rsDetail @simpanAspal=simpanAspal></edit-jalan-aspal>
    <edit-jalan-beton :rsDetail=rsDetail @simpanBeton=simpanBeton></edit-jalan-beton>
  </div>
</template>

<script>
import tabel_data from './component/tabel.vue'
import axios from '@/config/Axios'
import Base from '@/config/Mixins_base'
import {
  BTabs, BCol, BRow,
  BTab, BCard, BButton
} from 'bootstrap-vue'
import EdikKondisiRusaJalan from './component/EdikKondisiRusaJalan.vue'
import EditJalanBeton from './component/EditJalanBeton.vue'
import EditJalanAspal from './component/EditJalanAspal.vue'
export default {
  components: {
    BTabs, BCol, BRow,
    BButton,
    BTab, tabel_data, BCard, EdikKondisiRusaJalan, EditJalanBeton, EditJalanAspal
  },
  data() {
    return {
      rsData: [], rsDetail: {}, balai: [], id_bidang: ""
    }
  },
  mixins: [Base],
  mounted() {
    this.loadData();
    this.balai_bidang();

  },
  methods: {
    LoadData(val) {
      this.id_bidang = val.id_bidang;
      this.loadData();

    },
    async balai_bidang() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/kondisi/balai_bidang',
        data: {

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.balai = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    DetailData(value) {
      this.rsDetail = value
    },
    async simpanBeton(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/kondisi/act_kondisi',
        data: {
          id_kondisi: value.id_kondisi,
          jenis_insert: "Beton",
          id_ruas_jalan: value.id_jalan_ruas,
          panjang_ruas: value.panjang_ruas,
          panjang_beton: value.panjang_beton,
          panjang_aspal: value.panjang_aspal,
          aspal_baik: value.aspal_baik,
          aspal_sedang: value.aspal_sedang,
          aspal_rusak_ringan: value.aspal_rusak_ringan,
          aspal_rusak_berat: value.aspal_rusak_berat,
          beton_baik: value.beton_baik,
          beton_sedang: value.beton_sedang,
          beton_rusak_ringan: value.beton_rusak_ringan,
          beton_rusak_berat: value.beton_rusak_berat,

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.notification('warning', "", response.data.message);
          self.loadData();

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async simpanAspal(value) {
      const self = this;
      await axios({
        method: 'POST',
        url: '/api/pu/ruas_jalan/kondisi/act_kondisi',
        data: {
          id_kondisi: value.id_kondisi,
          jenis_insert: "Aspal",
          id_ruas_jalan: value.id_jalan_ruas,
          panjang_ruas: value.panjang_ruas,
          panjang_beton: value.panjang_beton,
          panjang_aspal: value.panjang_aspal,
          aspal_baik: value.aspal_baik,
          aspal_sedang: value.aspal_sedang,
          aspal_rusak_ringan: value.aspal_rusak_ringan,
          aspal_rusak_berat: value.aspal_rusak_berat,
          beton_baik: value.beton_baik,
          beton_sedang: value.beton_sedang,
          beton_rusak_ringan: value.beton_rusak_ringan,
          beton_rusak_berat: value.beton_rusak_berat,

        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rs = response.data.result;
          self.notification('warning', "", response.data.message);

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
    async loadData() {
      const self = this;
      await axios({
        method: 'PUT',
        url: '/api/pu/ruas_jalan/kondisi/load',
        data: {
          id_bidang: self.id_bidang
        },
        headers: {
          'Authorization': self.isAuthenticated
        }
      })
        .then(function (response) {
          self.rsData = response.data.result;
          //self.rs_level = response.data.result;

        }).catch(err => {
          self.pesan = err.message;
          self.notification('warning', "Error", err.message);
        });
    },
  },
  props: {

  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },

}
</script>

<style></style>