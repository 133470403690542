<template>
  <b-row>
    <b-col md="7" sm="8" class="my-1">
      <b-form-group>
        <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="nama" :options="balai"
          @input="LoadData()" />
      </b-form-group>
    </b-col>

    <b-col md="1" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block   "></label>
        <b-form-select id="perPageSelect" v-model="perPage" size="md" :options="pageOptions" />
      </b-form-group>
    </b-col>

    <b-col md="4" class="my-1">
      <b-form-group label="" label-align-sm="right" label-size="md" label-for="filterInput" class="mb-0">
        <b-input-group size="md">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Pencarian Ruas Jalan" />
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(wbs)="data">
          <b-row>
            <b-col md="4">
              <h6 class="text-primary"> </h6>
              <small class="text-primary">NamaRuas Jalan</small>
              <h5 class="text-primary"><strong>{{ data.item.nama_ruas_jalan }}</strong></h5>
              <h6 class="mtm text-primary"> <small><strong>{{ data.item.nama }}, {{ data.item.nm_wil }}</strong> </small>
                <br><small class="text-primary">No Ruas <b> {{ data.item.no_ruas_jalan }}</b></small>
              </h6>
              <h6 class="text-primary"> <small> Panjang total Ruas Jalan</small><br><strong
                  style="font-size: 30px;"><small></small>{{ data.item.panjang }} </strong>KM

              </h6>

              <!-- <b-button variant="success" class="btn-icon mb-1" block v-if="data.item.id == '1'">
                <feather-icon icon="CheckCircleIcon" class="mr-50" size='15' /> Tervalidasi
              </b-button>
              <b-button variant="warning" class="btn-icon mb-1" block v-if="data.item.id != '1'">
                <feather-icon icon="BellIcon" class="mr-50" size='15' /> Status Pendataan
              </b-button> -->
            </b-col>
            <b-col md="4">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <small>Panjang Ruas Jalan Perkerasan Aspal</small> <br>
                      <h2><strong class="text-primary">{{ data.item.panjang_aspal }} </strong><small>KM</small></h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small>Baik</small>
                      <h5><strong class="text-dark">{{ data.item.aspal_baik }}</strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small>Sedang</small>
                      <h5><strong class="text-dark">{{ data.item.aspal_sedang }} </strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small>R-Ringan</small>
                      <h5><strong class="text-dark">{{ data.item.aspal_rusak_ringan }} </strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small>R-Berat</small>
                      <h5><strong class="text-dark">{{ data.item.aspal_rusak_berat }} </strong><small></small></h5>
                    </b-col>
                  </b-row>

                  <b-button variant="primary" class="btn-icon" block size="sm" v-b-modal.modal-1
                    v-if="data.item.status_beton == null" @click="DetailData(data.item)">
                    Edit KondisiRuas Jalan
                  </b-button>

                  <b-button variant="primary" class="btn-icon" block size="sm" v-b-modal.modal-1
                    v-if="data.item.status_beton == 'Pengajuan'" @click="DetailData(data.item)">
                    Edit KondisiRuas Jalan
                  </b-button>
                  <b-button variant="primary" class="btn-icon" block size="sm" v-b-modal.modal-1
                    v-if="data.item.status_beton == 'Revisi'" @click="DetailData(data.item)">
                    Direvisi Admin PU
                  </b-button>
                  <b-button variant="primary" class="btn-icon" block size="sm" disabled
                    v-if="data.item.status_beton == 'Valid'" @click="DetailData(data.item)">
                    Data Kondisi Jalan Sudah Tervalidasi
                  </b-button>

                </div>
              </b-alert>
            </b-col>
            <b-col md="4">
              <b-alert variant="warning" show>
                <div class="alert-body">
                  <b-row>
                    <b-col md="12">
                      <small class="text-primary">Panjang Ruas Jalan Perkerasan Beton</small> <br>
                      <h2><strong class="text-primary">{{ data.item.panjang_beton }} </strong><small>KM</small></h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small class="text-primary">Baik</small>
                      <h5><strong class="text-dark">{{ data.item.beton_baik }} </strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small class="text-primary">Sedang</small>
                      <h5><strong class="text-dark">{{ data.item.beton_sedang }} </strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small class="text-primary">R-Ringan</small>
                      <h5><strong class="text-dark">{{ data.item.beton_rusak_ringan }}</strong><small></small></h5>
                    </b-col>
                    <b-col md="3" xs="3" sm="3" lg="3">
                      <small class="text-primary">R-Berat</small>
                      <h5><strong class="text-dark">{{ data.item.beton_rusak_berat }}</strong><small></small></h5>
                    </b-col>
                  </b-row>
                  <b-button variant="warning" class="btn-icon" block size="sm" v-b-modal.modal-2
                    v-if="data.item.status_aspal == null" @click="DetailData(data.item)">
                    Edit KondisiRuas Jalan
                  </b-button>
                  <b-button variant="warning" class="btn-icon" block size="sm" v-b-modal.modal-2
                    v-if="data.item.status_aspal == 'Pengajuan'" @click="DetailData(data.item)">
                    Edit KondisiRuas Jalan
                  </b-button>
                  <b-button variant="danger" class="btn-icon" block size="sm" v-b-modal.modal-2
                    v-if="data.item.status_aspal == 'Revisi'" @click="DetailData(data.item)">
                    Direvisi Admin PU
                  </b-button>
                  <b-button variant="warning" class="btn-icon" block size="sm" disabled
                    v-if="data.item.status_aspal == 'Valid'" @click="DetailData(data.item)">
                    Data Kondisi Jalan Sudah Tervalidasi
                  </b-button>
                </div>
              </b-alert>

            </b-col>
          </b-row>
        </template>
      </b-table>
    </b-col>

    <b-col cols="12">
      Total Ruas jalan {{ items.length }}
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
import vSelect from 'vue-select'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BAlert, vSelect
  },
  props: {
    rsData: {},
    balai: {}
  },
  data() {
    return {
      selected: {
        nama: "Semua Balai Bidang"
      },
      bpj: [
        { value: null, text: 'Silahkan Pilih BPJ' },
        { value: 'a', text: 'BPJ Cilacap' },
        { value: 'b', text: 'BPJ Wonosobo' },
      ],
      perPage: 5,
      pageOptions: [5, 50, 100, 200, 1000],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [{
        key: 'wbs',
        label: '',
        thStyle: {
          display: 'none'
        }
      }],
    }
  },
  computed: {
    items: {
      get: function () {
        return this.rsData;
      },
      set: function (newValue) {
        return this.rsData = newValue;
      }
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key
        }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  emits: ["DetailData", "LoadData"],
  methods: {
    LoadData() {
      this.$emit("LoadData", this.selected)
    },
    DetailData(value) {
      this.$emit("DetailData", value)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>
<style>
.btn-block {
  display: block;
  width: 100%;
}

.mtm {
  margin-top: -5px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>