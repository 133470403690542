<template>
  <div>
    <!-- basic modal -->
    <b-modal id="modal-1" title="Edit Kondisi Ruas Jalan Pengkerasan Aaspal" ok-disabled no-close-on-backdrop hide-footer
      size="lg">
      <b-alert variant="primary" show>
        <div class="alert-body">
          <b-row>
            <b-col md="8">
              <h6 class="text-primary"> </h6>
              <small class="text-primary">NamaRuas Jalan</small>
              <h6 class="text-primary">
                {{ rsDetail.nama_ruas_jalan }}
              </h6>
              <h6 class="mtm text-primary"> {{ rsDetail.nama }} , {{ rsDetail.nm_wil }}
                <br><small class="text-primary">No Ruas {{ rsDetail.no_ruas_jalan }}</small>
              </h6>

            </b-col>
            <b-col md="4">

              <small>Panjang Ruas Jalan Aspal </small> <br>
              <h1>
                <strong class="text-primary">{{ rsDetail.panjang_aspal }}</strong><small>KM, </small>
              </h1>

              <h5><small class="text-primary">Total Panjang Ruas Jalan Aspal + Beton </small>
                <strong> {{ rsDetail.panjang }} KM</strong>
              </h5>
            </b-col>
          </b-row>
        </div>
      </b-alert>
      <b-alert variant="warning" show v-if="rsDetail.note_aspal != null">
        <div class="alert-body">
          <span><strong>Catatan dari Admin PU</strong> </span>
          <br>
          <small>{{ rsDetail.note_aspal }}</small>
        </div>
      </b-alert>
      <b-row>
        <b-col>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>

                <!--  This field is required-->
                <b-col md="6">
                  <b-form-group label="Panjang Ruas Jalan" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Panjang Jalan KM">
                      <b-form-input type="number" v-model="rsDetail.panjang_aspal"
                        :state="errors.length > 0 ? false : null" placeholder="Panjang Jalan KM" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">


                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Kondisi Baik" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Baik">
                      <b-form-input type="number" v-model="rsDetail.aspal_baik" :state="errors.length > 0 ? false : null"
                        placeholder="Panjang Jalan KM" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Kondisi Sedang" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Sedang">
                      <b-form-input type="number" v-model="rsDetail.aspal_sedang"
                        :state="errors.length > 0 ? false : null" placeholder="Panjang Jalan KM" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Kondisi Rusak Ringan" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Rusa">
                      <b-form-input type="number" v-model="rsDetail.aspal_rusak_ringan"
                        :state="errors.length > 0 ? false : null" placeholder="Panjang Jalan KM" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Kondisi Rusak Berat" label-for="username">
                    <validation-provider #default="{ errors }" rules="required" name="Rusak">
                      <b-form-input type="number" v-model="rsDetail.aspal_rusak_berat"
                        :state="errors.length > 0 ? false : null" placeholder="Panjang Jalan KM" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

              </b-row>
              <b-row>
                <b-col>
                  <i>Penjumlahan kondisi jalan Baik, Sedang, Rusak Rngan, Rusak Berat <strong>{{
                    (parseFloat(rsDetail.aspal_baik)
                      + parseFloat(rsDetail.aspal_sedang)
                      + parseFloat(rsDetail.aspal_rusak_ringan) +
                      parseFloat(rsDetail.aspal_rusak_berat)).toFixed(2) }}</strong></i>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col cols="12">
                  <b-button variant="primary" type="submit" @click.prevent="validationForm" size="sm">
                    <feather-icon icon="SaveIcon" class="mr-50" size='15' /> Update
                  </b-button>
                  <b-button variant="warning" type="submit" @click.prevent="close" size="sm" class="mr-1 ml-1">
                    <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' />Batal
                  </b-button>
                </b-col>
              </b-row>

            </b-form>
          </validation-observer>
        </b-col>
      </b-row>


    </b-modal>

  </div>
</template>

<script>
import Base from '@/config/Mixins_base'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BModal, BButton, VBModal, BAlert, BRow, BCol, BFormInput, BFormGroup, BForm, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required, integer, min, digits, length,
} from '@validations'

export default {
  components: {
    BButton,
    BModal,
    BAlert, BRow, BCol, BFormInput, BFormGroup, BForm, ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      required, integer, min, digits, length,
    }
  },
  mixins: [Base],
  mounted() {


  },
  emits: ["simpanAspal"],
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit("simpanAspal", this.rsDetail)
          this.close();
        }
      })
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'modal-1')
    }
  },
  props: {
    rsDetail: {}
  },
  computed: {
    // setTahun: {
    //   get: function () {
    //	 return this.tahun;
    //   },
    //   set: function (newValue) {
    //	 return this.tahun = newValue;
    //   }
    // }, 

  },
}
</script>